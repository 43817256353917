
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import { HotelsListTableItem } from '@/modules/cluster/interfaces';
import HotelLine from './hotel-line.vue';
import Skeleton from './skeleton.vue';
import ClusterPagination from '../cluster-pagination.vue';

@Component({
    components: {
        HotelLine,
        Skeleton,
        ClusterPagination,
    },
})
export default class HotelsList extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Array })
    tableData!: {
        table: HotelsListTableItem[];
        component: {
            element: Vue;
            props: {[key: string]: any}
        };
    }[];

    @Prop({ type: Number })
    pagesCount!: number;

    @Prop({ type: Boolean })
    isLoading!: boolean;

    get isSearchQuery() {
        return !!this.clusterService.clusterHotelSearchQuery;
    }
}
