
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import type Day from '@/modules/common/types/day.type';
import { EventSet } from '@/modules/events/models/event-set.model';

export interface AssessmentData {
    isPastDay: boolean;
    isNA: boolean;
    colorModificator: string;
}

@Component
export default class CalendarChain extends Vue {
    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;

    @Inject(EventsManagerServiceS)
    protected eventsManagerService!: EventsManagerService;

    @Prop({ type: Boolean })
    public skeleton!: boolean;

    @Prop({ type: Array })
    private assessments!: AssessmentData[];

    @Prop({ type: Boolean })
    public disableEvents!: boolean;

    @Prop({ type: Number })
    public hotelId!: number;

    eventSet: EventSet | null = null;

    get calculatedAssestments() {
        return this.assessments || Array(30).fill({});
    }

    async loadEvents(hotelId: number, marketId: number) {
        const { month, year } = this.documentFiltersService;
        this.eventSet = await this.eventsManagerService
            .loadHotelEvent(month, year, hotelId, marketId);
    }

    hasHolidayEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
        return this.eventsManagerService
            .hasDateHolidayEvents(date);
    }

    hasLocalEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        if (!this.eventSet) {
            return false;
        }

        const collection = this.eventSet?.getCollection(date);

        if (!collection) {
            return false;
        }

        const { my, market } = collection;

        return !!my.length || !!market.length;
    }

    cellOut() {
        this.$emit('cellout');
    }

    checkCell(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellhover', e.target);
        }
    }

    cellClick(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellclick', e.target);
        }
    }
}
