
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import type Day from '@/modules/common/types/day.type';

@Component
export default class ListDaysHeader extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Boolean })
    isLoading!: boolean;

    @Prop({ type: Array })
    headerData!: {
        name: string;
        value: string;
        sort?: 1 | 0 | -1;
    }[];

    get monthDays() {
        return this.documentFiltersService.days;
    }

    formatDay(day: Day) {
        return this.$tc(`dayLetter.${this.date(day).getDay()}`) + day;
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        const date = this.date(day);
        return (new Date()).toDateString() === date.toDateString();
    }

    handleSort(colValue: string) {
        this.$emit('sort', colValue);
    }
}
